import React from "react"
import {
  MajorColumn,
  MinorColumn,
  FullWidthColumn,
  FlexWrapMobile,
  HeroSection,
  PromoSlides
} from "../components/content-elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import md5 from "md5"
import { graphql } from "gatsby"
import BlogListing from "../components/blog_listing"
import GalleryListing from "../components/gallery_listing"
import UseSiteMetadata from "../hooks/use-site-metadata"

const HomePageTemplate = ({ data, location }) => {
  const { page, marketing, galleries, blogs } = data
  const { sitewideMarketing, slugsLookup } = UseSiteMetadata();


  const sliderSettings = {
    dots: true,
    autoplay: true,
    accessibility: true,
    arrows: false,
    autoplaySpeed: 3500,
    // pauseOnDotsHover: true,
    // pauseOnFocus: true,
    infinite: true,
    speed: 1800,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerMode: true,
    lazyLoad: true
    // vertical:true
    // customPaging: function(i) {
    //   return (
    //     <a>
    //       <img src={`${baseUrl}/abstract0${i + 1}.jpg`} />
    //     </a>
    //   );
    // },
  }

  const sliderItemCount = sitewideMarketing.highlighted_content?.length ?? 0
  const highlightedItemCount = marketing.frontmatter.highlighted_content?.length ?? 0

  return (
    <Layout location={location}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />

      <FullWidthColumn>

        <HeroSection
          headline={page.frontmatter.heading}
          body={page.frontmatter.subheading}
          image={sitewideMarketing.hero_logo}
          actions={[
            { to: slugsLookup['/pages/about/'], label: "Find out more" }
          ]}
        />
      </FullWidthColumn>

      {(() => {
        if (highlightedItemCount > 0) {
          return (
            <FullWidthColumn>
              <PromoSlides sliderSettings={sliderSettings} items={marketing.frontmatter.highlighted_content} />
            </FullWidthColumn>
          )

        } else if (sliderItemCount > 0) {

          return (
            <FullWidthColumn>
              <PromoSlides sliderSettings={sliderSettings} items={sitewideMarketing.highlighted_content} />
            </FullWidthColumn>
          )

        }
      })()}

      <FlexWrapMobile>
        <MinorColumn>
          <GalleryListing data={galleries} columns={2} />
        </MinorColumn>
        <MajorColumn>
          <BlogListing data={blogs} columns={3} />
        </MajorColumn>
      </FlexWrapMobile>
    </Layout>
  )
}

export const pageQuery = graphql`    

    query homePageData {
        ...GalleryListFragment
        ...BlogListFragment
        page: markdownRemark(fields: {slug: {eq: "/pages/home/"}}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(fields: {slug: {eq: "/pages/home/"}}) {
            ...SEOFields
            ...MarketingFields
        }
    }
`
export default HomePageTemplate