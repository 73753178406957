import { graphql } from "gatsby"
import React from "react"
import Listing from "./listing"

const BlogListing = ({
                       columns,
                       data,
                       title
                     }) => {

  const blogs = data
  const full_title = title===false ? '' : (title || 'latest')

  const getEntry = (entry) => {

    const { id, excerpt: teaser, frontmatter, fields } = entry
    const { date, title: headline, description, tags, image } = frontmatter
    const { collection, slug } = fields
    return {
      id: id,
      date: date,
      headline: headline,
      teaser: description || teaser,
      tag: tags,
      image: image,
      slug: slug,
      collection: collection
    }
  }

  const processEntries = () => {
    let items = blogs.nodes.map(entry => {
      return getEntry(entry)
    })
    return items
  }

  return (
    <Listing columns={columns} title={full_title} children={processEntries()} />
  )
}


export const query = graphql`
    fragment BlogListFragment on Query {
        blogs: allMarkdownRemark(filter: {fields: {collection: {eq: "blog"}}}, sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                id
                excerpt
                frontmatter {
                    template
                    date
                    image
                    title
                    description
                    subtitle
                    tags
                    date
                }
                fields {
                    collection
                    slug
                }
            }
        }
    }
`

export default BlogListing